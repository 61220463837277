<template>
    <div>
        <input hidden type="text" class="form-control" id="WarningLetterId" v-model="WarningLetterId">
        <div class="modal fade" id="warningLetterModal" tabindex="-1" aria-labelledby="warningLetterModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="warningLetterModalLabel" class="font-weight-bold">{{ModalTitle}}{{WarningLetterId}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>

                            <CRow>
                                 <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Employee Name</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <v-select id="EmployeeId" class="pb-3" :options="EmployeeIdData" v-model="EmployeeId" :disabled="disabledEmp ? false : true" />
                                    <label id="errorEmployeeId" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow class = "pb-3">
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Tanggal Terima</label>
                                   <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol> 
                                <CCol sm="7">
                                    <date-picker
                                        v-model="ReceivedDate"
                                        format="DD-MM-YYYY"
                                        placeholder="Pilih Tanggal"
                                        value-type="YYYY-MM-DD"
                                        style="width:100%"
                                        ></date-picker>
                                    <label id="errorRecievedDate" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Alasan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <textarea class="form-control" rows="4" v-model="Reason"></textarea>
                                    <label id="errorReason" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <br>
                            
                            <label id="errorWarningLetterGeneral" class="form-error" style="display: none; color: red;"></label>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import warningLetterService from '../Script/WarningLetterService.js';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { concatAST } from 'graphql';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'WarningLetterForm',
    props: ['reload'],
    components: {
        'date-picker': datePicker,
    },
    data: function () {
        return {

            ModalTitle : '',
            disabledEmp : true,
            WarningLetterId: '',
            EmployeeId:'',
            ReceivedDate: '',
            EmployeeIdData:[],
            Reason: '',
            Error: 0,
            SaveType: '',
        }
    },
    // mounted(){

    // },
    methods: {
        async addClick(){ 
             
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.disabledEmp = true;

            this.ModalTitle = 'Add Warning Letter'       
            this.WarningLetterId = '';

            this.EmployeeIdData = await warningLetterService.getEmployeeIdQuery();
            this.EmployeeId = ''

            this.ReceivedDate = '';
            this.Reason = '';

            this.SaveType = 'Add';
            window.$('#warningLetterModal').modal('show');
        },
        async editClick(id, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Edit Warning Letter: '

            var data = await warningLetterService.getWarningLetterData(id);

            this.WarningLetterId = data.warning_letter_id;

            this.EmployeeIdData = await warningLetterService.getEmployeeIdQuery();
            this.EmployeeId = this.EmployeeIdData.find(c => c.value == data.employee_Id);

            this.Reason = data.reason;
            this.ReceivedDate = data.received_date;

            this.disabledEmp = false;
            this.SaveType = 'Edit';
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Warning Letter: ';
            }

            window.$('#warningLetterModal').modal('show');
        },
        saveClick() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.EmployeeId == ''){
                this.errorShow('errorEmployeeId');
                this.Error++;
            }
            if(this.Reason == ''){
                this.errorShow('errorReason');
                this.Error++;
            }
            if(this.ReceivedDate == '' || this.ReceivedDate == null){
                this.errorShow('errorRecievedDate');
                this.Error++;
            }
            
            if(this.Error == 0){
                //Add
                const warningLetterData = {
                    employee_id: this.EmployeeId.value,
                    reason: this.Reason,
                    received_date: this.ReceivedDate
                };

                this.$loading(true);
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : warningLetterData
                    }

                    warningLetterService.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#warningLetterModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                //Edit
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.WarningLetterId,
                        data : warningLetterData
                    }

                    warningLetterService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#warningLetterModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>
